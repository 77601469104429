<template>

  <div class=" content_container">
    <div class='box'>
      <div class="top">

        <p>投诉内容</p>

      </div>
      <div class="cont">
        <el-row>
          <el-col :span="6">
            <div class="item">
              <!-- <div class="item-img-warper">
                <img
                  class="item-img"
                  :src="$config.baseUrl + product.files_path"
                  @click="toDetail(goods)"
                />
              </div> -->
              <!-- <div class="item-info">
               <div
                  class="item-name"
                  @click="toDetail(goods)"
                >{{ goods.name }}</div>
                <div class="item-price">
                 
                </div>

                <div> {{ order.shop_name }}</div>  
            </div> -->
            </div>

          </el-col>

          <el-col :span="24">

            <el-row>
              <el-col :span="4">订单号：</el-col>
              <el-col
                :span="20"
                style="margin-bottom:10px"
              >
                {{ order.out_request_no }}

              </el-col>
            </el-row>
            <!-- <el-row>
              <el-col :span="4">订单名称：</el-col>
              <el-col
                :span="20"
                style="margin-bottom:10px"
              >
                {{ product.name }}

              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="4">价格</el-col>
              <el-col
                :span="20"
                style="margin-bottom:10px"
              >
                <span class="price">
                  ￥{{ parseFloat(order.total / 100).toFixed(2) }}
                </span>

              </el-col>
            </el-row>

            <el-row>
              <el-col :span="4">店铺名称：</el-col>
              <el-col
                :span="20"
                style="margin-bottom:10px"
              >
                {{ order.shop_name }}

              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">投诉类型：</el-col>
              <el-col
                :span="20"
                style="margin-bottom:10px"
              >
                <el-select
                  v-model=" type"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>

              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">问题描述</el-col>
              <el-col :span="20">
                <p v-if='num==0'> {{mark}}</p>
                <el-input
                  v-else
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="mark"
                >
                </el-input>
                <dl>
                  <dd>
                    <p
                      class="tu"
                      v-if='num==0'
                    >

                      <img
                        v-for="(img, i) in gallery2"
                        :key="i"
                        :src='img'
                        @click="handlePictureCardPreview2"
                      >
                      <el-dialog
                        :visible.sync="dialogVisible"
                        style="width:100%"
                      >
                        <img
                          style="width:100%;height:500px "
                          :src="dialogImageUrl"
                          alt=""
                        >
                      </el-dialog>
                    </p>

                    <uploader
                      v-else
                      field="img"
                      value-type="urlStr"
                      v-model="gallery"
                      exts="png||jpg||gif"
                    />
                    <el-dialog :visible.sync="dialogVisible2">

                    </el-dialog>

                  </dd>

                </dl>

              </el-col>
            </el-row>

          </el-col>
        </el-row>
      </div>

      <div class="btn">
        <el-button
          type="info"
          @click="toSend()"
        >
          提交
        </el-button>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible2: false,
      dialogImageUrl: "",
      type: "",
      mark: "",

      options: [
        { label: "发货问题", id: "1" },
        { label: "产品问题", id: "2" },
        { label: "发票问题", id: "3" },
        { label: "客服售后问题", id: "4" }
      ],

      order: {},
      product:{},

      content: "",
      gallery: [],
      gallery2: [],
      avatar_url: "",
      dialogVisible: false,
      num: 1
    };
  },

  watch: {},
  computed: {},
  mounted() {
    this.getDetail();
    this.num = this.$route.query.num;
  },
  methods: {
    handlePictureCardPreview2(file) {
      this.dialogImageUrl = file.target.src;
      this.dialogVisible = true;
    },
    //发布
    toSend() {
       let data = {
          mark: this.mark,
          type: this.type,
          out_request_no:this.order.out_request_no,
          order_id:this.order.order_id, 
          order_time: this.order.date_added,
          id_value: this.product.product_id,
          shopid: this.order.shop_id,
          pic: this.gallery,
          goodid: this.product.order_product_id,
          goodname: this.product.name,         
           good_logo:this.product.files_path ,
           shopname:this.order.shop_name,
           member_id:this.$store.state.member.id,
           member_name:this.$store.state.member.name  
}
        this.$post("home/goodcomplain", data).then(res => {
          console.log(res);
          this.$message.success("提交成功！");
          this.$router.push("/member-order");
       
      });
    },

    //获取订单详情信息
    getDetail() {
      this.$get("home/order/" + this.$route.query.order_id).then(res => {
        console.log("home/order", res);
        
        this.order=res
        // this.product=res.get_order_product.filter(f=>f.order_product_id== this.$route.query.product_id).[0]
          
        //  console.log('111',res.get_order_product.filter(f=>f.order_product_id== this.$route.query.product_id))
       });
    }
  },
  components: {},
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.tu {
  img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
}
.box {
  background: #fff;
  padding: 40px;
  margin-top: 40px;
}
.btn {
  text-align: center;
  .el-button {
    width: 200px;
  }
}
.content_container {
  box-sizing: border-box;
}
.item-img-warper {
  margin-bottom: 20px;
  text-align: center;
  img {
    width: 160px;
    height: 160px;
  }
}
.item-info {
  .item-name {
    font-weight: bold;
  }
  div {
    text-align: center;
    margin-bottom: 10px;
  }
}
.top {
  p {
    line-height: 45px;

    font-size: 16px;
    font-weight: bold;
  }
  text-align: center;
  dl {
    dd {
      display: inline-block;
      padding: 10px 30px;
    }
    text-align: center;
  }
}
.cont {
  padding: 20px;
  width: 70%;
  margin:0 auto;
  dl {
    padding: 20px 0;
  }
}
</style>
